.markdown {
}

.markdown ol {
    padding-left: theme("spacing.6");
}

.markdown ul {
    padding-left: theme("spacing.12");
}

.markdown ol {
    list-style-type: decimal;
}

.markdown ul {
    list-style-type: disc;
}

.markdown table,
.markdown th,
.markdown td {
    border: 1px solid #a2aab7;
    padding: 5px;
}

.markdown table {
    border: 1px solid;
    border-radius: 10px;
    border-collapse: collapse;
    border-spacing: 0px;
}

.markdown th {
    background-color: #393c40;
}
