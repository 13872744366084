//$primary-color: green;
$bg-color: gray;
$sticky-bg-color: orange;
$cell-font-size: 1em;
$cell-text-color: white;
@import "@silevis/reactgrid/styles.scss";

.rg-cell {
    background-color: $bg-color;
}

.rg-header-cell {
    background-color: red;
}

.rg-celleditor {
    border-width: 0;
    padding: 0;
}

.rg-textArea-celleditor {
    //visibility: hidden;
    //background-color: black;
    // position: absolute !important;
}
