@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --blue-dark: #19202B;
  --blue: #293547;
  --blue-light: #425572;
  --blue-lightest: #8296B6;

  --azure: #5352F6;
  --azure-pastel: #8495FF;
  --azure-light: #C6CBF8;
  --azure-lightest: #E2E5FB;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--blue) var(--blue-dark);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--blue-dark);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 14px;
  border: 3px solid var(--blue-dark);
}

a {
  color: var(--azure-pastel);
}